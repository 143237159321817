export default function errorHandling(error) {
  let response = error;

  try {
    response = error.response.data.errors[0];
  } catch {
    try {
      response = error.errors[0];
    } catch {
      try {
        response = error.response[0];
      } catch {
        if (!response) response = "Erro ao realizar o processo";
      }
    }
  }

  return response;
}