import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import axiosInstance from '../utils/axios'
import { PATH_DASHBOARD } from '../routes/paths';
import errorHandling from '../utils/errorHandling';

UsersProvider.propTypes = {
  children: PropTypes.node,
};

export const UsersContext = createContext({})

export const useUsersContext = () => {
  const context = useContext(UsersContext);

  if (!context) throw new Error('useUsersContext must be use inside UsersProvider');

  return context;
};

export function UsersProvider({ children }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const getUser = useCallback(async (id) => {
    try {
      const response = await axiosInstance.get(`api/v1/Usuario/${id}`)
      return response.data.data;
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
      return null;
    }
  }, [enqueueSnackbar])

  const getUsers = useCallback(async () => {
    try {
      const response = await axiosInstance.get('api/v1/Usuario')
      return response.data.data;
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
      return [];
    }
  }, [enqueueSnackbar])

  const createUser = useCallback(async (user) => {
    try {
      await axiosInstance.post('api/v1/Usuario', user)
      enqueueSnackbar('Usuário cadastrado com sucesso!');
      navigate(PATH_DASHBOARD.user.list);
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
    }
  }, [enqueueSnackbar, navigate])

  const updateUser = useCallback(async (user, isProfile = false) => {
    try {
      await axiosInstance.put('api/v1/Usuario', user)
      enqueueSnackbar('Usuário atualizado com sucesso!');

      if (isProfile)
        navigate(PATH_DASHBOARD.general.app);
      else
        navigate(PATH_DASHBOARD.user.list);
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
    }
  }, [enqueueSnackbar, navigate])

  const deleteUser = useCallback(async (id) => {
    try {
      await axiosInstance.delete(`api/v1/Usuario/${id}`)
      enqueueSnackbar('Usuário deletado com sucesso!');
      navigate(PATH_DASHBOARD.user.list);
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
    }
  }, [enqueueSnackbar, navigate])

  const getCombos = useCallback(async () => {
    try {
      const response = await axiosInstance.get('api/v1/Usuario/popula-combos')
      return response.data.data;
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
      return null;
    }
  }, [enqueueSnackbar])

  const getDashboard = useCallback(async () => {
    try {
      const response = await axiosInstance.get('api/v1/Usuario/dashboard')
      return response.data.data;
    } catch (error) {
      enqueueSnackbar(errorHandling(error), { variant: 'error' });
      return null;
    }
  }, [enqueueSnackbar])

  const value = useMemo(
    () => ({
      getUsers,
      getUser,
      createUser,
      updateUser,
      deleteUser,
      getCombos,
      getDashboard
    }), [getUsers, getUser, createUser, updateUser, deleteUser, getCombos, getDashboard]);

  return (
    <UsersContext.Provider
      value={value}
    >
      {children}
    </UsersContext.Provider>
  )
}
